import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { environment } from '../../../environments/environment'
var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
}

@Injectable({
  providedIn: 'root'
})
export class CreateNewRequestService {
  getUserDetailsURL = environment.createRequestURL + "getCostCenterDetails?userId="
  getBehalfOfUserURL = environment.createRequestURL + "getBehalfOfName?searchString=";
  getApproverListURL = environment.createRequestURL + "getApproverDetails?userId=";
  getAddressURL = environment.createRequestURL + "getShippingAddress?companyName=";
  getContactURL = environment.createRequestURL + "getShippingContactDetails?addressId="
  addRequestURL = environment.createRequestURL + "addRequest"
  getShipTypeURL = "/assets/shipType.json";
  getChargeTypeURL = "/assets/goodsChargeType.json";
  getIncotermsURL = "/assets/incoterms.json";
  getShipmentMethodURL = "/assets/shipmentMethod.json";
  getPriorityURL = "/assets/priority.json";
  getUOMURL = '/assets/UOM.json';
  getWeightUomURL = '/assets/weightUOM.json';
  getDefaultShipFromAddressURL = 'assets/defaultShipFromAddress.json'
  getFreightCostTypeURL = "/assets/freightCostType.json";
  getPreferredCarrierURL = "/assets/preferredCarrier.json";
  getModeOfTransportURL = "/assets/modeOfTransport.json";
  getShipmentOrPickupURL = "/assets/shipmentOrPickup.json";  
  getVehicleEngineTypeURL = "/assets/vehicleEngineType.json";
  getVehicleTypeURL = "/assets/VehicleType.json";
  getVehicleConditiontypeURL= "/assets/VehicleConditiontype.json";
  getImportofRecordListURL="/assets/importerRecord.json"
  getVehicleConditionURL = "/assets/vehicleCondition.json";
  getepaRatedRangeListURL= "/assets/epaRatedRange.json";
  getDutyListURL= "/assets/Duty.json";
  getdotHS7ListURL= "/assets/dotHS-7.json";
  getepa35201FormListURL= "/assets/epa3520-1Form.json";
  getPlantCodesURL = "/assets/plantCodes.json"; //Manager Approval is not required,If plantcode exists in plantCodes.json
  addItemDetailsURL = environment.createRequestURL + "addItem";
  addVehicleItemDetailsURL = environment.createRequestURL + "addVehicleItem"
  currencyConverterUSD = "https://web-services.oanda.com/rates/api/v2/rates/candle.json?base=JPY&quote=USD&api_key=kmagilavy";
  addShippingAddressURL = environment.createRequestURL + "addShipAndBillAddress";
  editRequestURL = environment.createRequestURL + "updateRequest";
  updateItemURL = environment.createRequestURL + "updateItemRequest";
  deleteItemURL = environment.createRequestURL + "deleteItemDetails";
  insertAttachmentsURL = environment.createRequestURL + "addAttachment";
  deleteAttachmentURL = environment.createRequestURL + "deleteAttachment";
  updateAddressDetailsURL = environment.createRequestURL + "updateShipAndBillAddress";
  updateVehicleItemURL = environment.createRequestURL + "updateVehicleRequest";
  getS3InfoURL = "https://1lmx15mg5m.execute-api.us-east-1.amazonaws.com/prod/api/getToken";
  addActivityStreamUrl = environment.notificationURL + "addActivityStream";
  updateBulkAddressURL = environment.addressURL + "editBulkAddress";
  updateBulkContactURL = environment.addressURL + "editBulkContact";
  getOthersDescURL = environment.createRequestURL + "getDescription";
  getItemDetailsURL = environment.createRequestURL + "getOthersItems";
  deleteInvalidRequestURL = environment.createRequestURL+"deleteInvalidRequest";
  insertBulkDetailsToDBURL = environment.createRequestURL+"insertBulkDetailsToDB";
  getBulkDetailsURL = environment.createRequestURL+"getBulkFileDetails?fileId=";
  sequenceResetURL = environment.createRequestURL + "sequenceReset";
  getItemDetailFromURL = environment.partsSearchURL + "getSAPGTSResponse";
  updateAttachmentURL = environment.createRequestURL + "updateAttachment";

  constructor(private readonly _http: HttpClient) { }

  getShipType(): Observable<any> {
    const url = this.getShipTypeURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getWeightUOM(): Observable<any> {
    const url = this.getWeightUomURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getDefaultShipFromAddress(): Observable<any> {
    const url = this.getDefaultShipFromAddressURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getChargeType(): Observable<any> {
    const url = this.getChargeTypeURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getIncoterms(): Observable<any> {
    const url = this.getIncotermsURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getShipmentMethod(): Observable<any> {
    const url = this.getShipmentMethodURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getPriority(): Observable<any> {
    const url = this.getPriorityURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getUOM(): Observable<any> {
    const url = this.getUOMURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getFreightCostType(): Observable<any> {
    const url = this.getFreightCostTypeURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getModeOfTransport(): Observable<any> {
    const url = this.getModeOfTransportURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getPreferredCarrier(): Observable<any> {
    const url = this.getPreferredCarrierURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getShipmentOrPickup(): Observable<any> {
    const url = this.getShipmentOrPickupURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getVehicleEngineType(): Observable<any> {
    const url = this.getVehicleEngineTypeURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getVehicleType(): Observable<any> {
    const url = this.getVehicleTypeURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getVehicleConditiontype(): Observable<any> {
    const url = this.getVehicleConditiontypeURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }

  getImportofRecordList(): Observable<any> {
    const url = this.getImportofRecordListURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getDutyList(): Observable<any> {
    const url = this.getDutyListURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getdotHS7List(): Observable<any> {
    const url = this.getdotHS7ListURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getepa35201FormList(): Observable<any> {
    const url = this.getepa35201FormListURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getepaRatedRangeList(): Observable<any> {
    const url = this.getepaRatedRangeListURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }

  getVehicleCondition(): Observable<any> {
    const url = this.getVehicleConditionURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getPlantCodes(): Observable<any> {
    const url = this.getPlantCodesURL;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getUserDetails(userId): Observable<any> {
    const url = this.getUserDetailsURL + userId;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getBehalfOfUsers(searchKey,userId): Observable<any> {
    const url = this.getBehalfOfUserURL + searchKey + '&userId=' + userId;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }

  getApproverList(userId): Observable<any> {
    const url = this.getApproverListURL + userId;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }

  getShippingAddress(searchKey, plantCode,addressType,shipType): Observable<any> {
    const url = this.getAddressURL + searchKey + '&plantCode=' + plantCode+ '&addressType=' + addressType+ '&shipType=' + shipType;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }

  getShippingContact(addressId): Observable<any> {
    const url = this.getContactURL + addressId
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }

  addRequest(createObj): Observable<any> {
    const url = this.addRequestURL
    return this._http.post(url, createObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  addItemDetails(itemsObj): Observable<any> {
    const url = this.addItemDetailsURL
    return this._http.post(url, itemsObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  addVehicleItemDetails(itemsObj): Observable<any> {
    const url = this.addVehicleItemDetailsURL
    return this._http.post(url, itemsObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  insertShippingAddress(addressObj): Observable<any> {
    const url = this.addShippingAddressURL
    return this._http.post(url, addressObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  currencyConverterToUSD(): Observable<any> {
    const url = this.currencyConverterUSD
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  editRequest(editObj): Observable<any> {
    const url = this.editRequestURL
    return this._http.post(url, editObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  deleteItemDetails(obj): Observable<any> {
    const url = this.deleteItemURL
    return this._http.post(url, obj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  updateItemDetails(deleteObj): Observable<any> {
    const url = this.updateItemURL
    return this._http.post(url, deleteObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  updateVehicleDetails(vehicleObj): Observable<any> {
    const url = this.updateVehicleItemURL
    return this._http.post(url, vehicleObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  updateShippingAddress(addressObj): Observable<any> {
    const url = this.updateAddressDetailsURL
    return this._http.post(url, addressObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  insertAttachments(attachObj): Observable<any> {
    const url = this.insertAttachmentsURL
    return this._http.post(url, attachObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  deleteAttachment(attachObj): Observable<any> {
    const url = this.deleteAttachmentURL
    return this._http.post(url, attachObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getS3Info(): Observable<any> {
    return this._http.get<any>(this.getS3InfoURL);
  }
  addActivityStream(activityStreamObj): Observable<any> {
    const url = this.addActivityStreamUrl
    return this._http.post(url, activityStreamObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  updateBulkAddress(addressObj): Observable<any> {
    const url = this.updateBulkAddressURL
    return this._http.post(url, addressObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  updateBulkContact(contactObj): Observable<any> {
    const url = this.updateBulkContactURL
    return this._http.post(url, contactObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getItemDetailFromAPI(partNumber): Observable<any> {
    //const url = 'https://d5ymvofcai.execute-api.us-east-1.amazonaws.com/prod/api/getSAPGTSResponse?part='+partNumber+'&sapclient=100'
    const url = this.getItemDetailFromURL + '?part='+partNumber+'&sapclient=100'
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getOthersDesc(description,plantCode,type): Observable<any> {
    if(type == 'itemNo'){
      var params = '?itemNo='+encodeURIComponent(description)+'&description=""';
    }
    else{
      var params = '?itemNo=""&description='+encodeURIComponent(description);
    }
    const url = this.getOthersDescURL + params + '&plantCode=' + plantCode;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getItemDetails(description,plantCode,type): Observable<any> {
    if(type == 'itemNo'){
      var params = '?itemNo='+encodeURIComponent(description)+'&description=';
    }
    else{
      var params = '?itemNo=&description='+encodeURIComponent(description);
    }
    const url = this.getItemDetailsURL + params + '&plantCode=' + plantCode;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  deleteInvalidRequest(deleteObj): Observable<any> {
    const url = this.deleteInvalidRequestURL;
    return this._http.post(url, deleteObj ,httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  insertBulkDetails(obj): Observable<any> {
    const url = this.insertBulkDetailsToDBURL;
    return this._http.post(url, obj ,httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getBulkDetails(fileId): Observable<any> {
    const url = this.getBulkDetailsURL+fileId;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  sequenceReset(seqObj): Observable<any> {
    const url = this.sequenceResetURL;
    return this._http.post(url, seqObj ,httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  updateAttachment(updateobj): Observable<any> {
    const url = this.updateAttachmentURL;
    return this._http.post(url,updateobj,httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }

  exchangeRate():  Observable<any> {
    const url ='https://api.exchangerate-api.com/v4/latest/JPY';
    return this._http.get(url)
      .map((response: any) => response).catch(this.handleError);
  }
  private handleError(error: HttpErrorResponse) {
    var errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMsg = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong
      errorMsg = `Backend returned code ${error.status}`;
    }
    // return an observable with a user-facing error message
    return throwError(errorMsg);
  }
}
